import React from 'react'
import { Navbar, Nav } from 'react-bootstrap';
const Header = () => {
    return (
        <Navbar bg="dark" expand="lg" variant="dark" className="d-flex justify-content-between w-100">
            <Navbar.Brand className='mx-5'><img class="logo" src="https://kestoneglobal.com/wp-content/uploads/2021/09/Kestone-Reverse.png" alt="Kestone" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end mx-5">
                <Nav>
                    <Nav.Link href="https://kestoneglobal.com/terms-of-service/" target='_blank'>Terms & Condition</Nav.Link>
                    <Nav.Link href="https://kestoneglobal.com/privacy-policy/" target='_blank'>Privacy Policy</Nav.Link>

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header

import React from 'react'

const Status = () => {
  return (
    <div>
      Success Page
    </div>
  )
}

export default Status

import React from 'react'
import { Button } from 'react-bootstrap'
import axios from "axios"
import { nanoid } from 'nanoid'

const JioPayment = (props) => {
    const handlePaymentLinkGenerate = async () => {
        try {
            const data = {
                merchantId: process.env.REACT_APP_JIOPAY_MERCHANTID,//unique id given by jio pay
                merchantLinkId: nanoid(20),
                amount: props?.formData?.amount,
                description: "testing purpose",
                pushSmsTo: props?.formData?.mobileNumber,
                pushEmailTo: props?.formData?.email,
                invoice: `Invoice${props?.formData?.email}_${Date.now()}`,
                customerName: props?.formData?.name,
                merchantReturnUrl: `https://payments.kestoneglobal.com`,
                merchantCallBackUrl: `https://payments-api.kestoneglobal.com/jiopay/callback`,
                checkout: {
                    allowed: [{
                        methodType: 110
                    }]
                },
                properties: {
                    udf1: "A1",
                    udf2: "A2",
                    udf3: "A3",
                    udf4: "A4",
                    udf5: "A5"
                },
                metadata: {
                    product: [{
                        productId: 4911899272,
                        validationKey: "Serial Number",
                        validationValue: "AEDSHJ07HG779",
                        transactionAmount: 5000
                    }]
                }
            }
            const response = await axios.post(
                "https://pp-apig.jiomoney.com/payments/jfs/cl/get_short_link",
                data,
                {
                    headers: {
                        "x-trace-id": props?.traceId,
                        "x-app-access-token": props?.accessToken,
                        "x-appid-token": props?.appIdentifierToken
                    },
                }
            );
            console.log(response, 'response');
            window.open(response?.data?.url,"_self");
            await axios.post(`${process.env.REACT_APP_BACKEND}/jiopay`, response?.data,{
                headers: {
                    "secretKey":process.env.REACT_APP_AUTH_SECRET_KEY,
                },
            })    
        } catch (error) {
            console.log(error, "error")
        }
    }
    return (
        <div className='d-flex align-items-center justify-content-center h-100'>
            <Button type="success" onClick={handlePaymentLinkGenerate}>Pay now</Button>
        </div>
    )
}


export default JioPayment

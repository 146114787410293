import React, { useState } from 'react'
import { Container, Form, Button } from 'react-bootstrap';
import axios from "axios";
import useRazorpay from 'react-razorpay'
import JioPayment from '../components/JioPayment';
import { v4 as uuidv4 } from 'uuid';
import Header from '../components/Header';
const Home = () => {
    const [home, setHome] = useState(true);
    const [paymentPage, setPaymentPage] = useState(false);
    const [jioPaymentPage, setJioPaymentPage] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [appIdentifierToken, setAppIdentifierToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [traceId] = useState(uuidv4());
    const [Razorpay] = useRazorpay();
    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        email: '',
        amount: 0,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your logic for handling form submission here
        console.log(formData);
        setHome(false);
        setPaymentPage(true);
    };

    const postRazorpayResponse = async (values) => {
        try {
            console.log(values, "values")
            await axios.post(`${process.env.REACT_APP_BACKEND}/razorpay/saveOrder`, values, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    maxBodyLength: Infinity,
                    "secretKey": process.env.REACT_APP_AUTH_SECRET_KEY
                },
            });
            return
        } catch (ex) {
            return
        }
    }

    const handleRazorpayPayment = async () => {
        // Add your Razorpay payment logic here
        console.log('Processing Razorpay payment:', formData);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND}/razorpay/createOrder`,
                { amount: formData?.amount * 100 },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        maxBodyLength: Infinity,
                        "secretKey": process.env.REACT_APP_AUTH_SECRET_KEY
                    },
                }
            );
            //console.log(response, "response");
            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_TEST, // Enter the Key ID generated from the Dashboard
                amount: `${formData?.amount * 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: 'INR',
                name: 'Donation',
                description: 'Test Transaction',
                // image: "https://example.com/your_logo",
                image: "https://inquizitiveminds.com/assets/img/iq-icon.ico",
                order_id: response.data.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
                prefill: {
                    name: formData?.name,
                    email: formData?.email,
                    contact: formData?.mobileNumber,
                },
                handler: async function (response) {
                    //console.log(response);

                    postRazorpayResponse(response);
                    setHome(true);
                    setPaymentPage(false);
                    setFormData({
                        name: '',
                        mobileNumber: '',
                        email: '',
                        amount: 0,
                    })
                },
                modal: {
                    escape: false,
                    ondismiss: function () {
                        alert("Payment Failed!");
                        setHome(true);
                        setPaymentPage(false);
                        setFormData({
                            name: '',
                            mobileNumber: '',
                            email: '',
                            amount: 0,
                        })
                    },
                },
                theme: {
                    color: '#3399cc',
                },
                notes: {
                    address: 'https://payments.kestoneglobal.com/',
                },
            }

            const rzp1 = new Razorpay(options)

            rzp1.on('payment.failed', function (response) {
                // alert(response.error.code)
                //alert(response.error.description);
                //console.log(response, 'error')
                // alert(response.error.source)
                // alert(response.error.step)
                // alert(response.error.reason)
                // alert(response.error.metadata.order_id)
                // alert(response.error.metadata.payment_id)
                // Toast(response.error.description);
            })
            rzp1.on('payment.closed', function (response) {
                //console.log(response)
            })

            rzp1.open()
        } catch (error) {
            console.log(error);
        }
    };

    const handleJioPayPayment = async () => {
        // Add your Jio Pay payment logic here
        console.log('Processing Jio Pay payment:', formData);
        try {
            const response = await axios.post(
                "https://pp-apig.jiomoney.com/jfs/v1/app/authenticate",
                {
                    application: {
                        clientId: process.env.REACT_APP_JIOPAY_CLIENTID
                    },
                    authenticateList: [
                        {
                            "mode": 22,
                            "value": process.env.REACT_APP_JIOPAY_VALUE
                        }
                    ],
                    scope: "SESSION",
                    purpose: 2
                },
                {
                    headers: {
                        "x-trace-id": traceId,
                        maxBodyLength: Infinity,
                    },
                }
            );
            console.log(response, "response");
            if (response.data?.status !== "SUCCESS") {
                console.log("something went wrong,please refresh");
                return
            }
            setAccessToken(response?.data?.session?.accessToken?.tokenValue);
            setAppIdentifierToken(response?.data?.session?.appIdentifierToken);
            setRefreshToken(response?.data?.session?.refreshToken?.tokenValue);
            setJioPaymentPage(true);
            setHome(false);
            setPaymentPage(false);

        } catch (error) {
            console.log(error);
        }


    };


    return (
        <>
            <Header />
            {home && (
                <Container className='p-5'>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formMobileNumber">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="Enter your mobile number"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formAmount">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter the amount"
                                name="amount"
                                value={formData.amount}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className='m-3'>
                            Submit
                        </Button>
                    </Form>
                </Container>
            )}
            {paymentPage && (
                <>
                    <div className='d-flex align-items-center justify-content-center h-100'>
                        <Button variant="primary" onClick={handleRazorpayPayment} className='me-3'>
                            Pay with Razorpay
                        </Button>

                        <Button variant="success" onClick={handleJioPayPayment}>
                            Pay with Jio Pay
                        </Button>
                    </div>
                </>
            )}
            {jioPaymentPage && (
                <JioPayment accessToken={accessToken} appIdentifierToken={appIdentifierToken} refreshToken={refreshToken} formData={formData} traceId={traceId} />
            )}
        </>
    )
}

export default Home

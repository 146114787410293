import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Status from './pages/Status';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Home />}
          exact
        />
        <Route
          path="/home"
          element={<Status />}
          exact
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
